import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getRegions = createAsyncThunk('regions/getRegions', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/regions`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getRegionsCount = createAsyncThunk('regions/getRegionsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/regions/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleRegion = createAsyncThunk('regions/getSingleRegion', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/regions/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        region : response.data
    }
})

export const deleteRegion = createAsyncThunk('regions/deleteRegion', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/regions/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postRegion = createAsyncThunk('regions/postRegion', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/regions`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editRegion = createAsyncThunk('regions/editRegion', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/regions/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const regionsSlice = createSlice({
    name: 'regions',
    initialState: {
        data: [],
        count: 1,
        params: {},
        region: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getRegions.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getRegionsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleRegion.fulfilled, (state, action) => {
            state.region = action.payload.region
        })
    }
})

export default regionsSlice.reducer