import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getUnits = createAsyncThunk('machines/getUnits', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/units`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getFarmAccesses = createAsyncThunk('machines/getFarmAccesses', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/farm-accesses`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getMachines = createAsyncThunk('machines/getMachines', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/machines`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getMachineGroups = createAsyncThunk('machines/getMachineGroups', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/machine-groups`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getMachinesCount = createAsyncThunk('machines/getMachinesCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/machines/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleMachine = createAsyncThunk('machines/getSingleMachine', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/machines/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        machine : response.data
    }
})

export const deleteMachine = createAsyncThunk('machines/deleteMachine', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/machines/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postMachine = createAsyncThunk('machines/postMachine', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/machines`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editMachine = createAsyncThunk('machines/editMachine', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/machines/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})


export const machinesSlice = createSlice({
    name: 'machines',
    initialState: {
        data: [],
        count: 1,
        params: {},
        machine: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getMachines.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getMachinesCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleMachine.fulfilled, (state, action) => {
            state.machine = action.payload.machine
        })
    }
})

export default machinesSlice.reducer