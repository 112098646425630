// ** React Imports
import { lazy } from 'react'
import NotAllowed from '../../../../crm/pages/misc/NotAllowed'
import NotAuthorized from '../../../../crm/pages/misc/NotAuthorized'

const Login = lazy(() => import('../../../../crm/pages/authentication/login/Login'))
const Register = lazy(() => import('../../../../crm/pages/authentication/register/Register'))
const ForgotPassword = lazy(() => import('../../../../crm/pages/authentication/forgot-password/ForgotPassword'))
const ResetPassword = lazy(() => import('../../../../crm/pages/authentication/reset-password/ResetPassword'))
const BusinessConditions = lazy(() => import('../../../../crm/pages/misc/BusinessConditions'))

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/register',
    element: <Register />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/not-allowed',
    element: <NotAllowed />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/not-authorized',
    element: <NotAuthorized />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/business-conditions',
    element: <BusinessConditions />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  }
]

export default AuthenticationRoutes
