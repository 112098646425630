import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getFarms = createAsyncThunk('workers/getFarms', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/farms`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getWorkers = createAsyncThunk('workers/getWorkers', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getWorkersCount = createAsyncThunk('workers/getWorkersCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleWorker = createAsyncThunk('workers/getSingleWorker', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        worker : response.data
    }
})

export const deleteWorker = createAsyncThunk('workers/deleteWorker', async ({user_id, farms}) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/delete-worker`, {
        user_id,
        farms
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postFarmAccess = createAsyncThunk('workers/postFarmAccess', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/farm-accesses`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editFarmAccess = createAsyncThunk('workers/editFarmAccess', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/farm-accesses/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteFarmAccess = createAsyncThunk('workers/deleteFarmAccess', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/farm-accesses/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getUsers = createAsyncThunk('workers/getUsers', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const workersSlice = createSlice({
    name: 'workers',
    initialState: {
        data: [],
        count: 1,
        params: {},
        worker: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getWorkers.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getWorkersCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleWorker.fulfilled, (state, action) => {
            state.worker = action.payload.worker
        })
    }
})

export default workersSlice.reducer