import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getUserMe = createAsyncThunk('profile/getUserMe', async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`)

    if (response.status !== 200) {
        return null
    }

    return {
        user : response.data
    }
})

export const editMyAccount = createAsyncThunk('workers/editMyAccount', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        user: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUserMe.fulfilled, (state, action) => {
            state.user = action.payload.user
        })
    }
})

export default profileSlice.reducer