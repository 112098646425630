import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const importLands = createAsyncThunk('lands/importLands', async data => {
    const formData = new FormData()
    formData.append("file", data.file)
    formData.append("farmId", data.farmId)

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/lands/import`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getLandTypes = createAsyncThunk('lands/getLandTypes', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/land-types`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getLands = createAsyncThunk('lands/getLands', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/lands`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getLandsCount = createAsyncThunk('lands/getLandsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/lands/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleLand = createAsyncThunk('lands/getSingleLand', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/lands/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        land : response.data
    }
})

export const deleteLand = createAsyncThunk('lands/deleteLand', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/lands/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})


export const landsSlice = createSlice({
    name: 'lands',
    initialState: {
        data: [],
        count: 1,
        params: {},
        land: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getLands.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getLandsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleLand.fulfilled, (state, action) => {
            state.land = action.payload.land
        })
    }
})

export default landsSlice.reducer