// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import resetPassword from './resetPassword'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import email from '@src/views/apps/email/store'
import kanban from '@src/views/apps/kanban/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

//farm
import farms from './../crm/modules/farm/store'
import workers from './../crm/modules/worker/store'
import animals from './../crm/modules/animals/store'
import machines from './../crm/modules/machines/store'
import profile from './../crm/modules/profile/store'
import users from './../crm/modules/users/store'
import regions from './../crm/modules/regions/store'
import lands from './../crm/modules/lands/store'
import units from './../crm/modules/units/store'

const rootReducer = {
  farms,
  workers,
  animals,
  machines,
  profile,
  users,
  regions,
  lands,
  units,

  auth,
  resetPassword,
  todo,
  chat,
  email,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions
}

export default rootReducer
