import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getUsers = createAsyncThunk('users/getUsers', async params2 => {
    const params = {...params2}
    params["role.type_ne"] = "admin"
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getUsersCount = createAsyncThunk('users/getUsersCount', async params2 => {
    const params = {...params2}
    params["role.type_ne"] = "admin"
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleUser = createAsyncThunk('users/getSingleUser', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        user : response.data
    }
})

export const editUser = createAsyncThunk('users/editUser', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const sendBlockedUserEmail = createAsyncThunk('users/sendBlockedUserEmail', async user => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-blocked-email`, {
        user
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const sendUnblockedUserEmail = createAsyncThunk('users/sendUnblockedUserEmail', async user => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/send-unblocked-email`, {
        user
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const deleteUser = createAsyncThunk('users/deleteUser', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/users/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const blockUser = createAsyncThunk('users/blockUser', async ({id, blocked}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        blocked
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const usersSlice = createSlice({
    name: 'users',
    initialState: {
        data: [],
        count: 1,
        params: {},
        user: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getUsersCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleUser.fulfilled, (state, action) => {
            state.user = action.payload.user
        })
    }
})

export default usersSlice.reducer