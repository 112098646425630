import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const importAnimals = createAsyncThunk('animals/importAnimals', async data => {
    const formData = new FormData()
    formData.append("file", data.file)
    formData.append("farmId", data.farmId)

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/animals/import`, formData)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getAnimalCategories = createAsyncThunk('animals/getAnimalCategories', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/animal-categories`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getAnimals = createAsyncThunk('animals/getAnimals', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/animals`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getAnimalsCount = createAsyncThunk('animals/getAnimalsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/animals/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleAnimal = createAsyncThunk('animals/getSingleAnimal', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/animals/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        animal : response.data
    }
})

export const deleteAnimal = createAsyncThunk('animals/deleteAnimal', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/animals/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})


export const animalsSlice = createSlice({
    name: 'animals',
    initialState: {
        data: [],
        count: 1,
        params: {},
        animal: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAnimals.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getAnimalsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleAnimal.fulfilled, (state, action) => {
            state.animal = action.payload.animal
        })
    }
})

export default animalsSlice.reducer