import {Home, Users, Briefcase, GitHub, Truck, Map, Layers, Bookmark} from "react-feather"

export default [
    {
        id: 'users',
        title: 'users.menu',
        icon: <Users size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/users'
    },
    {
        id: 'farms',
        title: 'farms.menu',
        icon: <Home size={20} />,
        action: 'manage',
        roles: ['authenticated', 'admin'],
        resource: 'all',
        navLink: '/farms'
    },
    {
        id: 'workers',
        title: 'workers.menu',
        icon: <Briefcase size={20} />,
        action: 'manage',
        roles: ['authenticated', 'admin'],
        resource: 'all',
        navLink: '/workers'
    },
    {
        id: 'animals',
        title: 'animals.menu',
        icon: <GitHub size={20} />,
        action: 'manage',
        roles: ['authenticated', 'admin'],
        resource: 'all',
        navLink: '/animals'
    },
    {
        id: 'machines',
        title: 'machines.menu',
        icon: <Truck size={20} />,
        action: 'manage',
        roles: ['authenticated', 'admin'],
        resource: 'all',
        navLink: '/machines'
    },
    {
        id: 'regions',
        title: 'regions.menu',
        icon: <Map size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/regions'
    },
    {
        id: 'lands',
        title: 'lands.menu',
        icon: <Layers size={20} />,
        action: 'manage',
        roles: ['authenticated', 'admin'],
        resource: 'all',
        navLink: '/lands'
    },
    {
        id: 'units',
        title: 'units.menu',
        icon: <Bookmark size={20} />,
        action: 'manage',
        roles: ['admin'],
        resource: 'all',
        navLink: '/units'
    }
]