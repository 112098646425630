import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

export const getUnits = createAsyncThunk('units/getUnits', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/units`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        data : response.data,
        params
    }
})

export const getUnitsCount = createAsyncThunk('units/getUnitsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/units/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleUnit = createAsyncThunk('units/getSingleUnit', async id => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/units/${id}`)

    if (response.status !== 200) {
        return null
    }

    return {
        unit : response.data
    }
})

export const deleteUnit = createAsyncThunk('units/deleteUnit', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/units/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postUnit = createAsyncThunk('units/postUnit', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/units`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editUnit = createAsyncThunk('units/editUnit', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/units/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})


export const unitsSlice = createSlice({
    name: 'units',
    initialState: {
        data: [],
        count: 1,
        params: {},
        unit: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUnits.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getUnitsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleUnit.fulfilled, (state, action) => {
            state.unit = action.payload.unit
        })
    }
})

export default unitsSlice.reducer