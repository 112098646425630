import { DefaultRoute, AdminDefaultRoute } from '../router/routes'

import jwt from 'jsonwebtoken'
import moment from 'moment'

export const primaryColor = '#097969'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */

export const calculateAgeInMonths = (date) => {
  const momentBirthdate = moment(date)
  const momentNow = moment()
  return Math.floor(momentNow.diff(momentBirthdate, "months"))
}

export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'authenticated') return DefaultRoute
  if (userRole === 'admin') return AdminDefaultRoute
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

// crm
export const checkExpiration = (intervalFunc) => {
  const expiration = JSON.parse(localStorage.getItem('expiration'))
  const accessToken = JSON.parse(localStorage.getItem('accessToken'))
  if (accessToken === null) {
    localStorage.removeItem("expiration")
    clearInterval(intervalFunc)
  }
  //console.log(new Date(expiration))
  if (expiration !== null && new Date(expiration) < new Date()) {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("expiration")
    clearInterval(intervalFunc)
    return true
  }
  if (expiration === null) {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("expiration")
    clearInterval(intervalFunc)
    return true
  }
}

export const setExpiration = () => {
  const myinterval = 60 * 1000 // 1 min interval
  checkExpiration()
  const intervalFunc = setInterval(function() { checkExpiration(intervalFunc) }, myinterval)
  return intervalFunc
}

export const getJwtFromLocalStorage = () => {
  const accessToken = localStorage.getItem('accessToken')
  if (accessToken) {
    return JSON.parse(accessToken)
  }
  return null
}

export const decodeJwt = (token) => {
  return jwt.decode(token)
}

export const getParsedJwtFromLocalStorage = () => {
  const token = getJwtFromLocalStorage()
  if (token) {
    return decodeJwt(token)
  }
  return null
}

export const getUserFromJwt = (jwt) => {
  const parsed = decodeJwt(jwt)
  if (parsed) {
    return {
      id: parsed.id,
      username: parsed.username,
      role: parsed.role,
      confirmed: parsed.confirmed,
      blocked: parsed.blocked
    }
  }
  return null
}

export const getUserFromLocalJwt = () => {
  const parsed = getParsedJwtFromLocalStorage()
  if (parsed) {
    return {
      id: parsed.id,
      username: parsed.username,
      role: parsed.role,
      confirmed: parsed.confirmed,
      blocked: parsed.blocked
    }
  }
  return null
}

export const getLoggedUserId = () => {
  const userData = getUserFromLocalJwt()
  if (userData) {
    return userData.id
  }
  return null
}

export const getLoggedUserRole = () => {
  const userData = getUserFromLocalJwt()
  if (userData) {
    return userData.role
  }
  return null
}

export const formatMomentDate = (date, format) => {
  return moment(date).format(format)
}

const generateSameLetterInString = (symbol, times) => {
  let generated = ''
  for (let i = 0; i < times; i++) {
    generated = generated.concat(symbol)
  }
  return generated
}

export const formatInvoiceNumber = (format, prefix, year, number) => {
  try {
    const numberPartLength = (format.match(/N/g) || []).length
    const yearLength = (format.match(/Y/g) || []).length

    if (numberPartLength < 1 || yearLength < 2) {
      return null
    }

    let formatted = format.replace('PX', prefix)
    const yearPart = year.toString().slice(-yearLength)
    formatted = formatted.replace(generateSameLetterInString('Y', yearLength), yearPart)

    const numberLength = number.toString().length
    const numberPartDifference = numberPartLength - numberLength
    const numberPart = generateSameLetterInString('0', numberPartDifference).concat(number.toString())
    formatted = formatted.replace(generateSameLetterInString('N', numberPartLength), numberPart)

    return formatted
  } catch (e) {}
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
 export const isUserLoggedIn = () => getUserFromLocalJwt()
 export const getUserData = () => getUserFromLocalJwt()