import { lazy } from 'react'

//const DashboardEcommerce = lazy(() => import('../../../../views/dashboard/ecommerce'))
const Profile = lazy(() => import('../../../../crm/modules/profile'))

const FarmsList = lazy(() => import('../../../../crm/modules/farm/list'))
const FarmsAdd = lazy(() => import('../../../../crm/modules/farm/add'))
const FarmsEdit = lazy(() => import('../../../../crm/modules/farm/edit'))

const WorkersList = lazy(() => import('../../../../crm/modules/worker/list'))
const WorkersAdd = lazy(() => import('../../../../crm/modules/worker/add'))
const WorkersEdit = lazy(() => import('../../../../crm/modules/worker/edit'))

const AnimalsList = lazy(() => import('../../../../crm/modules/animals/list'))

const MachinesList = lazy(() => import('../../../../crm/modules/machines/list'))
const MachinesAdd = lazy(() => import('../../../../crm/modules/machines/add'))
const MachinesEdit = lazy(() => import('../../../../crm/modules/machines/edit'))

const UsersList = lazy(() => import('../../../../crm/modules/users/list'))
const UsersEdit = lazy(() => import('../../../../crm/modules/users/edit'))

const GeneralTerms = lazy(() => import('../../../../crm/pages/misc/GeneralTerms'))

const RegionsList = lazy(() => import('../../../../crm/modules/regions/list'))
const RegionsAdd = lazy(() => import('../../../../crm/modules/regions/add'))
const RegionsEdit = lazy(() => import('../../../../crm/modules/regions/edit'))

const LandsList = lazy(() => import('../../../../crm/modules/lands/list'))

const UnitsList = lazy(() => import('../../../../crm/modules/units/list'))

const UserRoutes = [
{
    path: '/profile',
    element: <Profile />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/ochrana-osobnych-udajov',
    element: <GeneralTerms />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: false
    }
  },
  {
    path: '/farms',
    element: <FarmsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/farms/add',
    element: <FarmsAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/farms/edit/:id',
    element: <FarmsEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/workers',
    element: <WorkersList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/workers/add',
    element: <WorkersAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/workers/edit/:id',
    element: <WorkersEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/animals',
    element: <AnimalsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/machines',
    element: <MachinesList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/machines/add',
    element: <MachinesAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/machines/edit/:id',
    element: <MachinesEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/users',
    element: <UsersList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/users/edit/:id',
    element: <UsersEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/regions',
    element: <RegionsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/regions/add',
    element: <RegionsAdd />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/regions/edit/:id',
    element: <RegionsEdit />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  },
  {
    path: '/lands',
    element: <LandsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["authenticated", "admin"]
    }
  },
  {
    path: '/units',
    element: <UnitsList />,
    meta: {
      action: 'manage',
      resource: 'all',
      roles: ["admin"]
    }
  }
]

export default UserRoutes
