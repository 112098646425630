import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"

import {getLoggedUserId} from "../../../../utility/Utils"

const loggedUserId = getLoggedUserId()

export const getCountries = createAsyncThunk('farms/getCountries', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/animal-sign-countries`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getRegions = createAsyncThunk('farms/getRegions', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/regions`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return response
})

export const getFarms = createAsyncThunk('farms/getFarms', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/farms`, {
        params
    })

    if (response.status !== 200) {
        return null
    }
    const arr = []
    let areParams = true
    if (params["farm_accesses.label"] || params["farm_accesses.user"]) {
        console.log(response.data)
        for (const farm of response.data) {
            for (const access of farm.farm_accesses) {
                if (access.label === params["farm_accesses.label"]  && access.user === params["farm_accesses.user"]) {
                    arr.push(farm)
                }
            }
        }
    } else {
        areParams = false
    }

    return {
        data : areParams === true ? arr : response.data,
        params
    }
})

export const getFarmsCount = createAsyncThunk('farms/getFarmsCount', async params => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/farms/count`, {
        params
    })

    if (response.status !== 200) {
        return null
    }

    return {
        count : response.data
    }
})

export const getSingleFarm = createAsyncThunk('farms/getSingleFarm', async ({id, isAdmin}) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/farms/${id}`, {
        params : {
            "farm_accesses.label" : isAdmin ? null : "owner",
            "farm_accesses.user" : isAdmin ? null : loggedUserId
        }
    })

    if (response.status !== 200) {
        return null
    }

    return {
        farm : response.data
    }
})

export const deleteFarm = createAsyncThunk('farms/deleteFarm', async id => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/farms/${id}`)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postFarm = createAsyncThunk('farms/postFarm', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/farms`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const postFarmAccess = createAsyncThunk('farms/postFarmAccess', async data => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/farm-accesses`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const editFarm = createAsyncThunk('farms/editFarm', async ({id, data}) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/farms/${id}`, data)

    if (response.status !== 200) {
        return null
    }

    return response
})

export const farmsSlice = createSlice({
    name: 'farms',
    initialState: {
        data: [],
        count: 1,
        params: {},
        farm: null
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getFarms.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        }).addCase(getFarmsCount.fulfilled, (state, action) => {
            state.count = action.payload.count
        }).addCase(getSingleFarm.fulfilled, (state, action) => {
            state.farm = action.payload.farm
        })
    }
})

export default farmsSlice.reducer